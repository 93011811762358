import React from 'react';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { createUseStyles } from "react-jss";
import { SVG } from '@svgdotjs/svg.js';
import './preview_section.scss';
import imageDef from '../../../../img/shutterstock.png';
import deviceUiNight from '../../../../img/device-UI-night.svg';
import deviceUiDay from '../../../../img/device-UI-day.svg';
import PreviewNotifications from './PreviewNotifications';
import { getError } from '../../../../../redux/reticles/reticles-selectors';

const gridRender = require('../../../utils/gridRender');
const coords2GridFinder = require('../../../utils/coords2GridFinder');

const useStales = createUseStyles({
  previewCanvas: {
    width: 1280,
    height: 960,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: (sceneUrl) => `url(${sceneUrl.device}), url(${sceneUrl.sceneUrl})`,
    borderRadius: 300,
  },
  previewDefault: {
    width: 1280,
    height: 960,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${deviceUiDay}), url(${imageDef})`,
    borderRadius: 300,
  },
})

export default function Preview_section({ sceneUrl, activeTabId, timeOfDay, activeLink}) {
  let [draw, setDraw] = useState(null);
  const [svg, setSvg] = useState('');
  const error = useSelector(getError);
  const classes = useStales(timeOfDay === "NIGHT" ? { sceneUrl: sceneUrl, device: deviceUiNight } : { sceneUrl: sceneUrl, device: deviceUiDay });
  const devices = useSelector((state) => state.device);
  let [currentDevice, setCurrentDevice] = useState('X-Sight 5');
  let [currentWidth, setCurrentWidth] = useState(1280);
  let [currentHeight, setCurrentHeight] = useState(960);
  let [minX, setMinX] = useState(760);
  let [minY, setMinY] = useState(760);
  let [maxX, setMaxX] = useState(0);
  let [maxY, setMaxY] = useState(0);
  let [scaleX, setScaleX] = useState(0);
  let [scaleY, setScaleY] = useState(0);
  let [currentCoeff, setCurrentCoeff] = useState(1);
  let previewGroup;

  const removeClasses = (svgData) => {
    const classArr = svgData.classes();

    classArr.forEach(className => {
      if (className !== 'preview-svg') {
        svgData.removeClass(className);
      }
    })
  }

  const renderCanvas = () => {
    const svgs = document.querySelectorAll('.preview-svg');

    svgs.forEach(el => el.remove());

    if (sessionStorage.getItem(activeTabId)) {
      if (draw) {
        draw.remove();
      }

      previewGroup = '';

      const previewSvg = JSON.parse(sessionStorage.getItem(activeTabId));

      if (previewSvg.svg) {
        setSvg(previewSvg.svg);

        draw = (SVG(previewSvg.svg.replace(/class="canvas-block"/gi, 'class="preview-svg"')).addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(1280, 960).viewbox(`0 0 1280 960`));
        
        removeClasses(draw);

        draw.each(function(item, arr) {
          if (arr[item].type === 'ellipse') {
            arr[item].remove()
          }
        })

        previewGroup = draw.group();
      }
    } else {
      if (draw) {
        draw.remove();
        previewGroup = '';
      }

      draw = (SVG().addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`));

      draw.each(function(item, arr) {
        if (arr[item].type === 'ellipse') {
          arr[item].remove()
        }
      })

      previewGroup = draw.group();
    };
  }

  const findtMinMaxPoints = (shape) => {
    if (shape.type === 'line') {
      if (shape.cx() < minX) {
        setMinX(shape.cx());
      }
  
      if (shape.cy() < minY) {
        setMinY(shape.cy());
      }
  
      if (shape.cx() > maxX) {
        setMaxX(shape.cx());
      }
  
      if (shape.cy() > maxY) {
        setMaxY(shape.cy());
      }
    } else if (shape.type === 'circle') {
      if (shape.cx() - (shape.radius() / 2) < minX) {
        setMinX(shape.cx() - (shape.radius() / 2));
      }
  
      if (shape.cy() - (shape.radius() / 2) < minY) {
        setMinY(shape.cy() - (shape.radius() / 2));
      }
  
      if (shape.cx() + (shape.radius() / 2) > maxX) {
        setMaxX(shape.cx() + (shape.radius() / 2));
      }
  
      if (shape.cy() + (shape.radius() / 2) > maxY) {
        setMaxY(shape.cy() + (shape.radius() / 2));
      }
    } else {
      if (shape.x() < minX) {
        setMinX(shape.x());
      }
  
      if (shape.y() < minY) {
        setMinY(shape.y());
      }
  
      if (shape.x() + shape.width() > maxX) {
        setMaxX(shape.x() + shape.width());
      }
  
      if (shape.y() + shape.height() > maxY) {
        setMaxY(shape.y() + shape.height());
      }
    }
  }

  const findNewCenter = (w, h) => {
    const cx = (((maxX + minX) / 2) / 760) * w;
    const cy = (((maxY + minY) / 2) / 760) * h;

    return [cx, cy];
  }

  const editShape2device = (shape, coeff, w, h, previewG) => {
    // const gridCoordArr = gridRender(w, h, coeff);
    let scaleX = 1280 / 760;
    let scaleY = 960 / 760;
    let minScale = Math.min(scaleX, scaleY);

    switch(shape.type) {
      case 'circle':
        // const circleR = shape.radius() / 2;
        // const circleXY = coords2GridFinder(shape.x(), shape.y(), gridCoordArr, w, h);

        // if (circleXY[0] < maxX) {
        //   setMaxX(circleXY[0]);
        // }

        // if (circleXY[1] < maxY) {
        //   setMaxY(circleXY[1]);
        // }

        // findtMinMaxPoints(shape);
        // const cPr = circleR / 600;
        // const newCR = cPr * w;
        const circleStrokeW = shape.attr('stroke-width');
        // const newCircleCoords = coords2GridFinder(shape.cx(), shape.cy(), gridCoordArr, w, h);

        // shape.stroke({width: circleStrokeW * coeff});

        shape.cx(shape.cx() * scaleX * coeff);
        shape.cy(shape.cy() * scaleY * coeff);
        shape.stroke({width: circleStrokeW * minScale * coeff});
        shape.radius(shape.radius() * minScale * coeff);
        
        // const scale = Math.min(scaleX, scaleY)
        // shape.radius(shape.radius * scale)
        // shape.center(newCircleCoords[0], newCircleCoords[1]);
        break;
      case 'line':
        const lineStrokeW = shape.attr('stroke-width');
        // const newLineCoords = coords2GridFinder(shape.cx(), shape.cy(), gridCoordArr, currentWidth, currentHeight);
        // const newXY1 = coords2GridFinder(shape.attr('x1'), shape.attr('y1'), gridCoordArr, w, h);
        // const newXY2 = coords2GridFinder(shape.attr('x2'), shape.attr('y2'), gridCoordArr, w, h);
        // const lineW = shape.width();
        // const lineH = shape.height();

        // const lineWPr = lineW / 600;
        // const newLineW = lineWPr * w;

        // findtMinMaxPoints(shape);

        // const lineHPr = lineH / 450;
        // const newLineH = lineHPr * h * coeff;

        // shape.plot([newXY1, newXY2]);
        // shape.width(newLineW * coeff);
        // shape.height(newLineH);
        shape.stroke({width: lineStrokeW * minScale * coeff});
        // shape.plot()
        let lArr = shape.array();

        lArr.forEach(el => {
          el[0] *= scaleX * coeff;
          el[1] *= scaleY * coeff;
        })

        shape.plot(lArr);

        // shape.center(newLineCoords[0], newLineCoords[1]);
        // shape.move(lx, ly);
        break;
      case 'rect':
        // const rectW = shape.width();
        // const rectH = shape.height();
        // const rectStrokeW = shape.attr('stroke-width') / 3;
        // const newRectCoords = coords2GridFinder(shape.x(), shape.y(), gridCoordArr, w, h);
        const rectStrokeW = shape.attr('stroke-width');
        // shape.stroke({width: rectStrokeW * coeff});
        // findtMinMaxPoints(shape);

        shape.x(shape.x() * scaleX * coeff);
        shape.y(shape.y() * scaleY * coeff);
        shape.width(shape.width() * scaleX * coeff);
        shape.height(shape.height() * scaleY * coeff);
        shape.stroke({width: rectStrokeW * minScale * coeff});
        break;
      case 'polygon':
        // const polyW = shape.width();
        // const polyH = shape.height();
        // const polyStrokeW = shape.attr('stroke-width') / 3;
        // const newPolyCoords = coords2GridFinder(shape.cx(), shape.cy(), gridCoordArr, w, h);
        const polyStrokeW = shape.attr('stroke-width');
        shape.stroke({width: polyStrokeW * minScale * coeff});
        shape.x(shape.x() * scaleX * coeff);
        shape.y(shape.y() * scaleY * coeff);
        shape.width(shape.width() * scaleX * coeff);
        shape.height(shape.height() * scaleY * coeff);
        // findtMinMaxPoints(shape);

        break;
      case 'g':
        if (shape.hasClass('number-group')) {
          // const gW = shape.width();
          // const gH = shape.height();
          // const newGrpCoords = coords2GridFinder(shape.x(), shape.y(), gridCoordArr, w, h);
          // findtMinMaxPoints(shape);
          shape.x(shape.x() * scaleX * coeff);
          shape.y(shape.y() * scaleY * coeff);
          shape.width(shape.width() * scaleX * coeff);
          shape.height(shape.height() * scaleY * coeff);
          
          shape.each(s => {
            // s.stroke({width: shape.attr('stroke-width') * minScale * coeff});
            console.log(s)
          })

          // shape.width(gW * coeff);
          // shape.height(gH * coeff);
          // shape.move(newGrpCoords[0], newGrpCoords[1]);
        }
        
        break;
      default:
    }

    if (!shape.id().includes('Svg')) {
      previewG.add(shape);
    }
  }

  const setCurrentDeviceData = () => {
    let [currentData] = devices.deviceData.filter(el => el.id === activeTabId);

    try {
      if (currentData && currentData.device !== currentDevice) {
        setCurrentDevice(devices.currentDevice);
      }
  
      if (currentData && currentData.device === 'X-Sight 5') {
        setCurrentWidth(1280);
        setCurrentHeight(960);
      } else if (currentData && currentData.device === 'Thor') {
        setCurrentWidth(1024);
        setCurrentHeight(768);
      }
    } catch(e) {
      console.log(e)
    }
  }

  const centerGroup = (w, h) => {
    // let centerPoints = findNewCenter(w, h);
    const buffSvg = JSON.parse(sessionStorage.getItem(activeTabId));
    const svgImage = SVG(buffSvg.svg);
    const imageChilds = svgImage.children();

    // let scaleX = 1280 / 7300;
    // let scaleY = 960 / 5500;
    // let minScale = Math.min(scaleX, scaleY);

    const buffG = SVG().group();
    
    imageChilds.forEach(el => {
      buffG.add(el);
    })

    let newCX = buffG.cx() / 760 * w;
    let newCY = buffG.cy() / 760 * h;

    try {
      if (previewGroup) {
        // previewGroup.x(minX * minScale);
        // previewGroup.y(minY * minScale);
        previewGroup.center(newCX, newCY);
      }
    } catch(e) {
      console.log(e);
    }

    buffG.remove();
  }

  const calculateShapeDimensions = () => {
    let [currentData] = devices.deviceData.filter(el => el.id === activeTabId);

    try {
      if (draw) {
        if (currentData && currentData.device === 'X-Sight 5') {
          if (currentData && currentData.lens === '5-25X') {
            draw.each((i, elementsArr) => {
              setCurrentCoeff(4.2);
              editShape2device(elementsArr[i], 4.2, 1280, 960, previewGroup);
              findtMinMaxPoints(elementsArr[i]);
            })
          } else if (currentData && currentData.lens === '3-15X') {
            draw.each((i, elementsArr) => {
              setCurrentCoeff(2.77);
              editShape2device(elementsArr[i], 2.77, 1280, 960, previewGroup);
              findtMinMaxPoints(elementsArr[i]);
            })
          }

          centerGroup(1280, 960);
          // previewGroup.center(centerPoints[0], centerPoints[1]);
          // draw = (SVG().addTo(`#preview_svg-${activeTabId}`).addClass('preview-svg').size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`));
          draw.size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`);
        }

        if (currentData && currentData.device === 'Thor') {
          if (currentData && currentData.sensor === '320') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 1.717, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 2.17, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 3.13, 1024, 768, previewGroup);
              })
            }

            centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          if (currentData && currentData.sensor === '640') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 1.59, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 2.25, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 3.33, 1024, 768, previewGroup);
              })
            }

            centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          if (currentData && currentData.sensor === '1280') {
            if (currentData && currentData.lens === '3-12X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 1.37, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '4-16X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 2.1, 1024, 768, previewGroup);
              })
            }

            if (currentData && currentData.lens === '5-20X') {
              draw.each((i, elementsArr) => {
                editShape2device(elementsArr[i], 2.73, 1024, 768, previewGroup);
              })
            }

            centerGroup(1024, 765);
            // let centerPoints = findNewCenter(1024, 765);

            // previewGroup.center(centerPoints[0], centerPoints[1])
          }

          draw.size(currentWidth, currentHeight).viewbox(`0 0 ${currentWidth} ${currentHeight}`);
        }
      }
    } catch(e) {
      console.log('Error!', e)
    }
  }

  useEffect(() => {
    // setMinX(1280);
    // setMinY(960);
    // setMaxX(0);
    // setMaxY(0);

    minX = 1280;
    minY = 960;
    maxX = 0;
    maxY = 0;
    
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();
  }, [activeTabId]);

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();

    centerGroup(currentWidth, currentHeight);
  }, [currentWidth, currentHeight, previewGroup, minX])

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();

    sessionStorage.setItem('devices', JSON.stringify(devices.deviceData));
  }, [devices])

  useEffect(() => {
    renderCanvas();
    calculateShapeDimensions();
  }, [currentDevice])

  useEffect(() => {
    renderCanvas();
    setCurrentDeviceData();
    calculateShapeDimensions();
    setScaleX(1280/760);
    setScaleY(960/760);
  }, [])

  return (
    <section className='preview_section'>
      <section
        id='preview_canvas'
        className={sceneUrl === '' ? classes.previewDefault : classes.previewCanvas}
        style={{width: `${currentWidth}px`, height: `${currentHeight}px`, borderRadius: currentDevice === 'Thor' ? '250px' : '300px'}}
      >
        <div 
          id={`preview_svg-${activeTabId}`}
          className='preview_canvas-block'
          style={{width: `${currentWidth}px`, height: `${currentHeight}px`}}
        ></div>
      </section>
      {error? (
        <PreviewNotifications activeLink={activeLink}/>
      ) : null}
      {/* <section className='preview_section-zoom'>
        <button className='preview_section-zoom-btn zoom-in_btn'></button>
        <input type='range' min='0' max='100' className='preview_zoom-input' />
        <button className='preview_section-zoom-btn zoom-out_btn'></button>
      </section> */}
    </section>
  );
}
