import axios from 'axios';
import { Base64 } from 'js-base64';
import {
    registerRequest,
    registerSuccess,
    registerError,
    confirmEmailRequest,
    confirmEmailSuccess,
    confirmEmailError,
    loginRequest,
    loginSuccess,
    loginError,
    googleLoginRequest,
    googleLoginSuccess,
    googleLoginError,
    logoutRequest,
    logoutSuccess,
    // logoutError,
    getCurrentUserRequest,
    getCurrentUserSuccess,
    getCurrentUserError,
    updateUserRequest,
    updateUserSuccess,
    updateUserError,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordError,
    resetPasswordCheckOtpRequest,
    resetPasswordCheckOtpSuccess,
    resetPasswordCheckOtpError,
    resetPasswordConfirmRequest,
    resetPasswordConfirmSuccess,
    resetPasswordConfirmError,
    resendConfirmationCodeRequest,
    resendConfirmationCodeSuccess,
    resendConfirmationCodeError
} from './auth-actions';

const getClientInfo = () => {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bEdg\/(\d+)/)
        if (tem != null) { return { name: 'Edge(Chromium)', version: tem[1] }; }
        tem = ua.match(/\bOPR\/(\d+)/)
        if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return Base64.encode(JSON.stringify({
        appId: window.location.host,
        appVersion: localStorage.getItem('appVersion'),
        platformName: window.navigator.platform,
        platformType: M[0],
        platformVersion: M[1]
    }));
};


const token = {
    set(token, userInfo) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        axios.defaults.headers.common.clientinfo = `${userInfo}`;
    },
    unset() {
        axios.defaults.headers.common.Authorization = '';
        axios.defaults.headers.common.clientinfo = '';
    },
};

axios.defaults.baseURL = 'https://id.od.atncorp.com';
// axios.defaults.baseURL = 'https://id.atncorp.com';

export const register = credentials => async dispatch => {
    dispatch(registerRequest());
    try {
        const response = await axios.post('/auth/sign-up', credentials);
        dispatch(registerSuccess(response.data));
    } catch (error) {
        dispatch(registerError(error.response.data.message));
    }
};


export const confirmEmail = credentials => async dispatch => {
    dispatch(confirmEmailRequest());
    try {
        const response = await axios.post('/auth/sign-up/confirm/email', credentials);
        token.set(response.data.token);
        dispatch(confirmEmailSuccess(response.data));
    } catch (error) {
        dispatch(confirmEmailError(error.response.data.message));
    }
};


export const login = credentials => async dispatch => {
    dispatch(loginRequest());
    try {
        const response = await axios.post('/auth/sign-in', credentials);
        const info = getClientInfo();

        token.set(response.data.token, info);
        dispatch(loginSuccess(response.data));
    } catch (error) {
        dispatch(loginError(error.response.data.message));
    }
};

export const googleLogin = credentials => async dispatch => {
    dispatch(googleLoginRequest());
    try {
        const response = await axios.post('/auth/google-auth/refresh-token', credentials);
        const info = getClientInfo();
        token.set(response.data.token, info);
        dispatch(googleLoginSuccess(response.data));
    } catch (error) {
        dispatch(googleLoginError(error.response.data.message));
    }
};

// export const googleLogin = credentials => async dispatch => {
//     // console.log('credentials', credentials)

//     dispatch(googleLoginRequest());
//     try {
//         const response = await axios.post('/auth/google-auth', credentials);
//         token.set(response.data.token);
//         dispatch(googleLoginSuccess(response.data));
//     } catch (error) {
//         dispatch(googleLoginError(error.message));
//     }
// };


export const logOut = () => async dispatch => {
    dispatch(logoutRequest());
    token.unset();
    dispatch(logoutSuccess());
};

// export const logOut = () => async dispatch => {
//     dispatch(logoutRequest());
//     try {
//         await axios.post('/logout');
//         token.unset();
//         dispatch(logoutSuccess());
//     } catch (error) {
//         dispatch(logoutError(error.message));
//     }
// };


export const getCurrentUser = () => async (dispatch, getState) => {
    const {auth: { token: persistedToken }} = getState();

    if (!persistedToken) {
        return;
    }
    const info = getClientInfo();

    token.set(persistedToken, info);

    dispatch(getCurrentUserRequest());

    try {
        const response = await axios.get('/users/user');

        dispatch(getCurrentUserSuccess(response.data));
    } catch (error) {
        dispatch(getCurrentUserError(error.response.data.message));
    }
};

export const updateUser = credentials => async dispatch => {
    dispatch(updateUserRequest());
    try {
        const response = await axios.patch('/users/update', credentials);
        dispatch(updateUserSuccess(response.data));
    } catch (error) {
        dispatch(updateUserError(error.response.data.message));
    }
};

export const resetPassword = credentials => async dispatch => {
    dispatch(resetPasswordRequest());
    try {
        const response = await axios.post('/auth/reset-password', credentials);
        dispatch(resetPasswordSuccess(response.data));
    } catch (error) {
        dispatch(resetPasswordError(error.response.data.message));
    }
};

export const resetPasswordCheckOtp = credentials => async dispatch => {
    dispatch(resetPasswordCheckOtpRequest());
    try {
        const response = await axios.post('/auth/reset-password/check-otp', credentials);
        dispatch(resetPasswordCheckOtpSuccess(response.data));
    } catch (error) {
        dispatch(resetPasswordCheckOtpError(error.response.data.message));
    }
};

export const resetPasswordConfirm = credentials => async dispatch => {
    dispatch(resetPasswordConfirmRequest());
    try {
        const response = await axios.post('/auth/reset-password/confirm', credentials);
        dispatch(resetPasswordConfirmSuccess(response.data));
    } catch (error) {
        dispatch(resetPasswordConfirmError(error.response.data.message));
    }
};

export const resendConfirmationCode = credentials => async dispatch => {
    dispatch(resendConfirmationCodeRequest());
    try {
        const response = await axios.post('/auth/sign-up/confirm/email/resend', credentials);
        dispatch(resendConfirmationCodeSuccess(response.data));
    } catch (error) {
        dispatch(resendConfirmationCodeError(error.response.data.message));
    }
};
