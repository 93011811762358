import { SVG } from '@svgdotjs/svg.js';

const clearSvgData = (data) => {
    let cloneData = SVG(data);

    const ellipseEl = cloneData.findOne('#aimpoint');

    ellipseEl.center(380, 380);

    cloneData.each(function(i, childrens) {
        if(childrens[i].attr('fill-opacity') === 0 && (childrens[i].fill() !== '#333' && childrens[i].fill() !== '#333333')) {
            childrens[i].attr({'fill-opacity': null});
            childrens[i].attr({'fill': null});
        }

        if (childrens[i].fill() === '#333' || childrens[i].fill() === '#333333') {
            childrens[i].fill('user color');
        }

        if (childrens[i].stroke() === '#333' || childrens[i].stroke() === '#333333') {
            childrens[i].stroke('user color');
        }
    })

    const cloneStringData = cloneData.svg();

    const clearedData = cloneStringData.replace('reticlemetadata', 'reticleMetadata');

    return clearedData;
}

export default clearSvgData;
